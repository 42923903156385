interface CardWrapperProps {
    /**
     * Wrapper content
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Role of this element
     */
    role?: string
    /**
     * Wrapper full width state
     */
    fullWidth?: boolean
    /**
     * Wrapper custom width
     */
    width?: number
    /**
     * Optional padding size
     */
    paddingSize?: keyof typeof paddingSizeMap
    /**
     * Optional on click event
     */
    onClick?: () => void
}

export const CardWrapper = ({ children, role, fullWidth, width, paddingSize = 'md', onClick }: CardWrapperProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <div
            onClick={() => handleClick()}
            className={`bg-white rounded-lg shadow-md max-w-full ${paddingSizeMap[paddingSize]} ${
                fullWidth ? 'w-full' : 'w-card'
            } ${role === 'button' ? 'hover:bg-[#FF520014] transition-colors' : ''}`}
            role={role}
            style={{
                width
            }}>
            {children}
        </div>
    )
}

const paddingSizeMap = {
    sm: 'p-3 sm:p-6',
    md: 'p-4 sm:p-8'
}
